import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: fit-content;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff7070;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }

    //  表用のCSS
    table {
      width: 100%;
      margin-bottom: 15px;


      td {
        padding: 5px;
        border: solid 1px rgba(200, 200, 200, 1);
        vertical-align: top;
      }
    }
  }

}

${BreakPoints.large} {
}
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0088 = (props: Props): JSX.Element => {
  const routeParam = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0067/'
  )
  const routeParam2 = useRouteParam('https://www.kintaicloud.jp/')
  const routeParam3 = useRouteParam('https://www.kintaicloud.jp/')
  return (
    <Wrapper>
      <ColumnH1 label="勤怠管理システムの選び方や比較ポイントは？ 導入方法も紹介" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2024.02.13</p>
      <section>
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <img
          src="/images/column/details/c0088.jpg"
          alt="キンクラコラム画像88"
        />
        <p>
          自社に合わない勤怠管理システムを導入してしまうと余計なコストがかかってしまう可能性があります。そのため、
          <span>自社に合ったシステムを選ぶことが重要</span>になります。
          <br />
          しかしながら、最適なシステムを選定するためには、どのようなことに気を付けなければいけないのか分からないという方もいるでしょう。
          <br />
          そこでこの記事では、勤怠管理システムの選び方から導入方法まで解説します。
          <br />
        </p>
        <div id="toc1">
          <h1>勤怠管理システムの選び方が重要な理由とは</h1>
        </div>
        <p>
          まずは、なぜ勤怠管理システムの選び方が重要な理由について解説します。
        </p>
        <div id="toc1-1">
          <h2>余計なコストがかかってしまう</h2>
        </div>
        <p>
          自社に合わない勤怠管理システムを選んでしまうと、余計なコストがかかってしまう恐れがあります。
          <br />
          極端な例ですが、従業員の人数が少ないにも関わらず、大企業向けの高価な勤怠管理システムを導入してしまい、その結果コストが余計にかかってしまうといったことが起こりえます。
          <br />
          このように、
          <span>
            自社に合わない勤怠管理システムを選定してしまうと、余計なコストが発生
          </span>
          してしまう場合があるため、慎重に選定することが重要です。
          <br />
        </p>
        <div id="toc1-2">
          <h2>自社の環境に合わないシステムを選んでしまう</h2>
        </div>
        <p>
          自社の環境に合わないシステムを選んでしまうことで、余計な労力が発生してしまう可能性があります。例えば、フレックスタイム制を導入して柔軟な働き方を採用しているが、導入した勤怠管理システムがこの柔軟性に対応できないため、調整が必要となり余分な手間が発生します。
          <br />
          <br />
          以上のようなリスクを避けるためにも、勤怠管理システムの選び方が重要になります。
        </p>
        <a href={routeParam} className="column-detail-link">
          参照記事：勤怠管理は経費精算付きのクラウドがおすすめ！ その理由とは...
        </a>
        <div id="toc2">
          <h1>自社に最適な勤怠管理システムの選び方や比較のポイントとは</h1>
        </div>
        <p>
          勤怠管理システムを選定する際に、様々なシステムと比較検討をすることでしょう。
          <br />
          ここでは比較する際のポイントをご紹介します。
        </p>
        <div id="toc2-1">
          <h2>自社に必要な機能があるか</h2>
        </div>
        <p>
          <span>
            自社の業務フローや特定のニーズに合致する機能が備わっているか
          </span>
          を確認することが重要です。
          <br />
          例えば、フレックスタイム制の対応可否や、独自の休暇制度に対応できるかといったことを確認します。また、余計なコストをかけないためにも、必要な機能のみを備えたシステムをなるべく選定することが大切です。
        </p>
        <div id="toc2-2">
          <h2>費用対効果は合うか</h2>
        </div>
        <p>
          自社の規模に合わない勤怠管理システムを導入してしまうと、当然余計なコストがかかります。
          <br />
          そのため、勤怠管理システムを導入しても費用対効果が合うかどうかを確認する必要があります。
        </p>
        <div id="toc2-3">
          <h2>サポート体制が整っているか</h2>
        </div>
        <p>
          導入時だけではなく、導入後もサポートはあるのか？また、トラブルが発生した場合にも、迅速に対応をしてくれるのか？等、
          <span>サポート体制を確認</span>することも重要です。
          <br />
          ただし、サポートの内容によっては追加費用が掛かる場合もあるため、それらも含めて確認しておく必要があります。
        </p>
        <div id="toc2-4">
          <h2>無料トライアルはあるか</h2>
        </div>
        <p>
          勤怠管理システムを導入する際には、無料トライアルのあるシステムを導入するのがおすすめです。どれだけ事前に調査をしても、実際に導入をしてみなければ、自社に適合するかどうかは分かりません。
          <br />
          そのため、事前調査では問題なかったが、導入後に様々なトラブルが発生してしまう可能性もあります。
          <span>
            無料トライアルのある勤怠管理システムであれば試験導入ができる
          </span>
          ため、そのようなリスクを避けられます。
          <br />
          <a href={routeParam2} className="column-detail-link">
            ⇒無料トライアルで「全機能」が使える、勤怠管理システム「キンクラ」とは
          </a>
        </p>
        <div id="toc2-5">
          <h2>法令の改正に対応しているか</h2>
        </div>
        <p>
          <span>法改正に合わせてシステムをアップデート</span>
          してくれるかどうかを確認することも重要です。
          <br />
          法令違反が起これば当然、罰則等のリスクが発生します。そのようなリスクを負わないためにも、法改正に対応してくれるシステムを選定するのが良いでしょう。ただし、システムによっては法改正のアップデートは有料の場合もあります。そのため、料金なども含めて確認することが大切です。
          <br />
          <br />
          以上のポイントを押さえることで、自社に適切な勤怠管理システムの選定ができます。
        </p>
        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}
        <div id="toc3">
          <h1>勤怠管理システムは選び方だけではなく導入も重要</h1>
        </div>
        <img
          src="/images/column/details/c0088_g01.jpg"
          alt="キンクラコラム画像88"
        />
        <p>
          勤怠管理システムは選び方だけではなく導入の手順も重要です。
          <br />
          以下の手順で、自社に最適な勤怠管理システムを導入することが出来ます。
        </p>
        <div id="toc3-1">
          <h2>勤怠管理システムを選定する</h2>
        </div>
        <p>
          まずは、導入をする勤怠管理システムを選定します。ここでは、各機能や料金プランなどを確認して、自社に最適な勤怠管理システムを選定することが重要です。
          <br />
          選定する際のポイントは、前の項目で解説をしていますので、そちらを参照してください。
        </p>
        <div id="toc3-2">
          <h2>勤怠管理システムを導入することを従業員へ事前に周知する</h2>
        </div>
        <p>
          勤怠管理システムを選定したら、次は従業員にシステムを導入することを
          <span>事前に周知</span>します。
          <br />
          とくに告知もなく、いきなり勤怠管理システムを導入してしまうと従業員は混乱してしまいます。そのため、勤怠管理システムをいつ導入するのか？そのシステムはどのようなもので、どのように操作をするのか？等を事前に周知しておきましょう。
        </p>
        <div id="toc3-3">
          <h2>試験導入をする</h2>
        </div>
        <p>
          勤怠管理システムの選定が完了し従業員への事前周知を実施したら、次は試験導入をします。
          <br />
          先述した通り、事前に調査をしても、実際に導入をしてみなければ、自社に適合するかどうかは分かりません。そのため、
          <span>まずは試験導入</span>
          をして操作性や機能に問題がないかどうかを検証することが大切です。
        </p>
        <div id="toc3-4">
          <h2>正式に導入を決定する</h2>
        </div>
        <p>
          試験導入で問題がなければ、勤怠管理システムを正式に導入します。
          <br />
          この段階では、試験導入で得られたフィードバックを活用して、最終的な調整を行うことが重要です。将来的に必要になりそうな機能であっても、
          <span>慣れるまでは現状必要な機能のみ</span>
          に抑えて導入することが成功への鍵です。
          <br />
          <br />
          以上の手順を取り入れることで、最適な勤怠管理システムを選ぶことができます。
        </p>
        <div id="toc4">
          <h1>勤怠管理システムは本格運用までの準備が重要</h1>
        </div>
        <p>
          勤怠管理システムを導入することによって、勤怠管理を効率化できます。
          <br />
          とはいえ、自社のニーズに合った機能を持つものを比較し、法令の改正に対応しているか、そしてサポート体制が整っているかを確認して導入することが大切です。
          <br />
          また、システムを導入した後は従業員に操作方法を周知し、試用期間を設けることが重要です。
          <br />
          それによって、システムが本格的に運用される前に問題点を見つけ出し、改善策につながります。
          <br />
          これらのステップを踏むことで、勤怠管理システムは企業の成長を支える強力なツールとなります。
          <br />
          <br />
          もし、勤怠管理システムの導入を検討しているならば、「キンクラ」がおすすめです。
          <br />
          「キンクラ」では、全機能が無料で使える「無料トライアル」を実施していますので、勤怠管理システムの導入を検討しているならば「キンクラ」をお試しください。
          <br />
        </p>
        <a href={routeParam3} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0088
