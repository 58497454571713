import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnDetailInner0088 from '../../../components/molecules/columnDetailInner0088'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import Conclusion001 from '../../../components/molecules/Conclusion001'
import Conclusion002 from '../../../components/molecules/Conclusion002'
import Conclusion003 from '../../../components/molecules/Conclusion003'
import Conclusion004 from '../../../components/molecules/Conclusion004'
import Conclusion005 from '../../../components/molecules/Conclusion005'
import Conclusion006 from '../../../components/molecules/Conclusion006'
import Conclusion007 from '../../../components/molecules/Conclusion007'
import Conclusion008 from '../../../components/molecules/Conclusion008'
import Conclusion009 from '../../../components/molecules/Conclusion009'
import CarouselBanner from '../../../components/molecules/carouselBanner'
import PopupBanner from '../../../components/molecules/popupBanner'
import ColumnAuthor004 from '../../../components/molecules/columnAuthor004'
import UsefulFunctionLayout019 from '../../../components/molecules/usefulFunctionLayout019'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0088 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '勤怠管理システムの選び方や比較ポイントは？ 導入方法も紹介\n',
    },
  ]

  // 0~8ランダム数字を保存
  const [no, setNo] = useState(-1)
  useEffect(() => {
    setNo(Math.floor(Math.random() * 9))
  }, [])

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="勤怠管理システムの選び方や比較ポイントは？ 導入方法も紹介"
        description="企業の効率的な勤怠管理のため、最適なシステムの選定と導入が不可欠です。本記事では、コスト削減につながるシステム選びと導入のポイントについて詳しく解説します。"
        ogUrl="https://kintaicloud.jp/column/details/C0088/"
        ogType="article"
        ogTitle="勤怠管理システムの選び方や比較ポイントは？ 導入方法も紹介"
        ogDescription="企業の効率的な勤怠管理のため、最適なシステムの選定と導入が不可欠です。本記事では、コスト削減につながるシステム選びと導入のポイントについて詳しく解説します。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0088.jpg"
      />
      <ColumnDetailsBody>
        {/** ポップアップバナー */}
        <PopupBanner />
        {/** 記事本体 */}
        <ColumnDetailInner0088 no={no} />

        {/** ここに9パターンの広告設定する */}
        {no === 0 && <Conclusion001 />}
        {no === 1 && <Conclusion002 />}
        {no === 2 && <Conclusion003 />}
        {no === 3 && <Conclusion004 />}
        {no === 4 && <Conclusion005 />}
        {no === 5 && <Conclusion006 />}
        {no === 6 && <Conclusion007 />}
        {no === 7 && <Conclusion008 />}
        {no === 8 && <Conclusion009 />}

        {/** オーサ情報 */}
        <ColumnAuthor004 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout019 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** カルーセルバナー */}
        <CarouselBanner />
        {/** 関連記事 */}
        {/* <ColumnRelationCards0088 /> */}
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0088

export const pageQuery = graphql`
  query C0088 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
